<template>
  <div class="main-map">
    <!-- <h1 @click="setCircle">ee</h1> -->
    <div ref="map-root" id="map" class="map">
        <p class="search-result results" v-if="allGeoForwardResult"><strong>{{allGeoForwardResult.length}}</strong> rezultata pretrage</p>
        <p class="search-result" v-if="geoForwardResult">{{geoForwardResult.display_name}}</p>
        <form @submit.prevent="searchForwardLocation">
            <div v-if="search" class="input-group">
                <input type="text" v-model="searchInput" class="form-control">
                <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary" @click.prevent="searchForwardLocation()">Pretraži</button>
                </div>
            </div>
        </form>
    </div>
  </div>
</template>

<script>
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
  import OSM from 'ol/source/OSM'
// import XYZ from "ol/source/XYZ";
import VectorLayer from "ol/layer/Vector";
import { fromLonLat, toLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import Style from "ol/style/Style";
import Circle from "ol/geom/Circle";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";
import {Circle as CircleStyle, Fill, Stroke} from 'ol/style';
import {Draw, Modify, Snap} from 'ol/interaction';
// import {GeometryCollection, Point, Polygon} from 'ol/geom';
import {circular} from 'ol/geom/Polygon';
import {getDistance} from 'ol/sphere';
import {transform} from 'ol/proj';
import MapService from '@/service/MapService.js'

export default {

    data() {
        return {
            map: undefined,
            circleVector: undefined,
            icons: [
                require("@/assets/pin.png"),
                "https://cdn-icons.flaticon.com/png/512/1799/premium/1799926.png?token=exp=1636235629~hmac=f657dc467c46432fd5f1c2f9a8a057d8",
            ],
            searchInput: '',
            geoForwardResult: undefined,
            allGeoForwardResult: undefined,

        };
    },

    props: {
        longitude: {
            type: Number,
        },
        latitude: {
            type: Number,
        },
        initalWorkOrder: {
            type: Object,
        },
        search: {
            type: Boolean,
            default: false,
        },

        readOnly: {
            type: Boolean,
            default: false,
        }

    },



    methods: {
        loadMap() {
            // this.map = new Map({
            //     target: "map",
            //     layers: [
            //     new TileLayer({
            //         source: new XYZ({
            //         url: "https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png",
            //         }),
            //     }),
            //     ],
            //     view: new View({
            //     center: fromLonLat([20.45, 44.21]),
            //     zoom: 7,
            //     }),
            // });
            const source = new VectorSource();


    const style = new Style({
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.6)',
    }),
    stroke: new Stroke({
        color: '#33cc33',
        width: 2,
    }),
    image: new CircleStyle({
        radius: 7,
        fill: new Fill({
        color: '#ffcc33',
        }),
    }),
    });

    const geodesicStyle = new Style({
    geometry: function (feature) {
        return feature.get('modifyGeometry') || feature.getGeometry();
    },
    fill: new Fill({
        color: 'rgba(255, 255, 255, 0.5)',
    }),
    stroke: new Stroke({
        color: '#ff3333',
        width: 2,
    }),
    image: new CircleStyle({
        radius: 7,
        fill: new Fill({
        color: 'rgba(0, 0, 0, 0)',
        }),
    }),
    });

    const vector = new VectorLayer({
        source: source,
        style: function (feature) {
            const geometry = feature.getGeometry();
            return geometry.getType() === 'GeometryCollection' ? geodesicStyle : style;
        },
    });
    this.circleVector = vector;


    let map = new Map({
        // the map will be created using the 'map-root' ref
        target: this.$refs['map-root'],
        layers: [
        // adding a background tiled layer
        new TileLayer({
            source: new OSM() // tiles are served by OpenStreetMap
        }),
        ],

        // the map view will initially show the whole world
        view: new View({
        zoom: 8,
        center: fromLonLat([20.4, 44.95]),
        constrainResolution: true
        }),
    })
    this.map = map;


    this.map.addLayer(this.circleVector);

    const defaultStyle = new Modify({source: source})
    .getOverlay()
    .getStyleFunction();

    this.setInitialCircle();

    if (!this.readOnly) {
        const modify = new Modify({
        source: source,
        style: function (feature) {
            feature.get('features').forEach(function (modifyFeature) {
            const modifyGeometry = modifyFeature.get('modifyGeometry');
            if (modifyGeometry) {
                const modifyPoint = feature.getGeometry().getCoordinates();
                const geometries = modifyFeature.getGeometry().getGeometries();
                const polygon = geometries[0].getCoordinates()[0];
                const center = geometries[1].getCoordinates();
                const projection = map.getView().getProjection();
                let first, last, radius;
                if (modifyPoint[0] === center[0] && modifyPoint[1] === center[1]) {
                // center is being modified
                // get unchanged radius from diameter between polygon vertices
                first = transform(polygon[0], projection, 'EPSG:4326');
                last = transform(
                    polygon[(polygon.length - 1) / 2],
                    projection,
                    'EPSG:4326'
                );
                radius = getDistance(first, last) / 2;
                } else {
                // radius is being modified
                first = transform(center, projection, 'EPSG:4326');
                last = transform(modifyPoint, projection, 'EPSG:4326');
                radius = getDistance(first, last);
                }
                // update the polygon using new center or radius
                const circle = circular(
                transform(center, projection, 'EPSG:4326'),
                radius,
                128
                );
                circle.transform('EPSG:4326', projection);
                geometries[0].setCoordinates(circle.getCoordinates());
                // save changes to be applied at the end of the interaction
                modifyGeometry.setGeometries(geometries);
            }
            });
            return defaultStyle(feature);
        },
        });



        modify.on('modifystart', function (event) {
        event.features.forEach(function (feature) {
            const geometry = feature.getGeometry();
            if (geometry.getType() === 'GeometryCollection') {
            feature.set('modifyGeometry', geometry.clone(), true);
            }
        });
        });

        modify.on('modifyend', function (event) {
        event.features.forEach(function (feature) {
            const modifyGeometry = feature.get('modifyGeometry');
            if (modifyGeometry) {
            feature.setGeometry(modifyGeometry);
            feature.unset('modifyGeometry', true);
            }
        });
        });



        map.addInteraction(modify);

        let draw, snap; // global so we can remove them later

            draw = new Draw({
                source: source,
                type: 'Circle',
            });
            draw.component = this;
            map.addInteraction(draw);
            snap = new Snap({source: source});
            map.addInteraction(snap);
            draw.on('drawstart', () => {
                source.clear();
            });

            draw.on('drawend', (event) => {
                let lonlat = toLonLat(event.feature.getGeometry().getCenter())
                let radius = event.feature.getGeometry().getRadius()
                this.initalWorkOrder.location_longitude = Math.round(lonlat[0] * 1000000) / 1000000;
                this.initalWorkOrder.location_latitude = Math.round(lonlat[1] * 1000000) / 1000000;
                this.initalWorkOrder.location_radius = Math.round(radius);
            });
        }

    },

        showModal() {
        this.$refs.mapDialog.show();
        },

        setInitialCircle() {
            if (this.initalWorkOrder?.location_longitude && this.initalWorkOrder?.location_latitude && this.initalWorkOrder?.location_radius)
                this.setCircle(
                    this.initalWorkOrder.location_longitude,
                    this.initalWorkOrder.location_latitude,
                    this.initalWorkOrder.location_radius,
                )
        },


        setCircle(lon, lat, radius) {
            let source = this.circleVector.getSource();
            let flatLocation = fromLonLat([lon, lat]);
            source.clear();
            this.map.getView().setCenter(flatLocation);
            this.map.getView().setZoom(16);

            const featureCircle = new Feature({
                geometry: new Circle(flatLocation, radius),
            });
            // circle = new Circle(transform([-96.1543889, 29.2542778], 'EPSG:4326', 'EPSG:3857')[2267255.075739303, 5594090.24331676], 1000);
            // geometryCircle = new Geometry(circle);
            // featureCircle = new Feature(geometryCircle);

            source.addFeature(featureCircle);

        },

        distanceBetweenPoints(lonlat1, lonlat2){
            let a = fromLonLat(lonlat1)
            let b = fromLonLat(lonlat2)
            var line = new LineString([a, b]);
            return Math.round(line.getLength() * 100) / 100;
        },

        updateSize() {
            this.map.updateSize();
        },

        async searchForwardLocation(){
            let locationData = await this.getForwardLocation();

            this.allGeoForwardResult = locationData;
            this.geoForwardResult = locationData[Math.floor(Math.random()*locationData.length)];
            this.setLocationView(this.geoForwardResult);
            this.setInitialCircle();
            console.log(this.geoForwardResult);
        },

        setLocationView(locationData) {
            this.initalWorkOrder.location_longitude = locationData.lon;
            this.initalWorkOrder.location_latitude = locationData.lat;
            this.initalWorkOrder.location_radius = 100;
        },

        async getForwardLocation() {
            let data = {
                location: this.searchInput
            }

            try {
                let result = await MapService.getForwardGeo(data);
                return result.data;
            } catch (error) {
                console.log(error);
                alert("Neuspesno trazenje")
            }
        }
    },

    mounted() {
        this.loadMap();
    },
}
</script>

<style>

    #map {
        width: 100%;
        height: 400px;
        display: inline-block;
    }

    p.search-result {
        font-size: 0.7em;
        margin: 0;
    }
</style>